<template>
  <div id="userOrder">
    <Dialog ref="dialog" :config="config"> </Dialog>
    <div>
      <div class="ohead">
        <div class="myInput">
          <i class="el-icon-search"></i>
          <input
            type="text"
            :placeholder="$fanyi('请输入关键词搜索')"
            v-model="form.keywords"
            @keydown.enter="getShopsList"
          />
          <button @click="getShopsList">{{ $fanyi("搜索") }}</button>
        </div>
      </div>
      <div class="tallys">
        <span
          :class="item.titleChecked ? 'active' : ''"
          @click="tabBtnClick(item)"
          v-for="(item, index) in tagLists"
          :key="index"
          >{{ item.tag_name }}</span
        >
      </div>
      <div class="shops" v-if="shopData.length != 0" v-loading="loading">
        <div
          class="shopsOpt"
          v-for="(shopDataitem, shopDataindex) in shopData"
          :key="shopDataindex"
        >
          <div class="optLeft flex fdcolmun">
            <h1
              class="goodsTitleBox"
              @click="
                $fun.routerToPage(
                  '/shopGoods?shopName=' +
                    shopDataitem.shop_name +
                    '&shopId=' +
                    shopDataitem.shop_id
                )
              "
              :title="shopDataitem.shop_name"
            >
              <p>{{ $fanyi("店铺名称") }}</p>

              <p class="shopName">
                {{ shopDataitem.shop_name }}
              </p>
            </h1>
            <div class="remark">
              <span>{{ $fanyi("备注") }}</span>
              <el-input
                v-model="shopDataitem.note"
                @change="changeNote(shopDataitem.id, shopDataitem.note)"
              ></el-input>
              <div class="btns">
                <img
                  @click.stop="shopTagOpen(shopDataitem)"
                  src="../../../../assets/user-img/shouChang.svg"
                  alt=""
                />
                <img
                  @click="delshop(shopDataindex, shopDataitem)"
                  src="../../../../assets/newImg/shanchu.svg"
                  alt=""
                />
              </div>
            </div>

            <div @click.stop class="pop-up" v-if="shopDataitem.showTagWin">
              <p v-for="(otem, ondex) in tagLists" :key="ondex">
                <input
                  type="checkbox"
                  :checked="otem.checked"
                  @click="
                    otem.checked = !otem.checked;
                    checkTagAdd(shopDataitem.id, shopDataitem);
                  "
                  name=""
                  id=""
                  class="che"
                /><span :title="otem.tag_name">{{ otem.tag_name }}</span>
                <img
                  style="cursor: pointer"
                  @click="delTag(otem.tag_id, ondex)"
                  src="../../../../assets/newImg/shanchu.svg"
                  alt=""
                />
              </p>
              <div class="addTagBtn">
                <input
                  type="text"
                  @keydown.enter="addTag(shopDataitem)"
                  v-model="addTagTxt"
                />
                <el-button type="primary" @click="addTag(shopDataitem)">{{
                  $fanyi("添加")
                }}</el-button>
              </div>
            </div>
          </div>
          <div class="optRight">
            <div
              class="carouselBtn"
              @click="shopCarouselChange('left', shopDataindex)"
            >
              <img src="../../../../assets/user-img/jaintouzuo.svg" alt="" />
            </div>

            <el-carousel
              ref="shopCarousel"
              trigger="click"
              width="100px"
              height="100px"
              :autoplay="false"
              arrow="never"
              indicator-position="none"
            >
              <el-carousel-item
                v-for="(goodsItem, goodsIndex) in shopDataitem.goods"
                :key="goodsIndex"
              >
                <div class="optRightCon">
                  <div
                    class="shopGoods"
                    v-for="(iu, sndex) in goodsItem"
                    :key="sndex"
                    @click="$fun.toCommodityDetails(iu.goods_id)"
                  >
                    <el-image :src="iu.imgUrl">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                      <div slot="placeholder">
                        <i class="el-icon-loading"></i>
                      </div>
                    </el-image>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
            <div
              class="carouselBtn"
              @click="shopCarouselChange('right', shopDataindex)"
            >
              <img src="../../../../assets/user-img/jaintouyou.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="tishi">
        {{ $fanyi(pageMsg) }}
      </div>
      <div class="allpag">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.page"
          :page-size="form.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Dialog from "../../../../components/public/Dialog.vue";

export default {
  data() {
    return {
      pageMsg: "暂无数据",
      tagLists: [], //标签列表
      shopData: [], //收藏店铺列表
      addTagTxt: "", //添加标签的文本
      loading: true,
      total: 0,
      form: {},
      config: {
        top: "20vh",
        width: "500px",
        title: this.$fanyi("提示"),
        btnTxt: [this.$fanyi("删除"), this.$fanyi("取消")],
      }, // 弹框属性
    };
  },
  components: {
    Dialog,
  },
  computed: {},
  created() {
    this.getTagData();
    this.form.pageSize = 5;
    this.getShopsList("one");
  },
  mounted() {
    document.addEventListener("click", this.bodyCloseMenus);
  },
  methods: {
    // ***********************标签事件******************************************************
    // 添加标签
    addTag(item) {
      if (!this.addTagTxt) return false;
      this.$api
        .favoriteTagAdd({
          type: 2,
          tag_name: this.addTagTxt,
        })
        .then((res) => {
          //console.log("favoriteTagDelete", res);
          if (res.code != 0) return;
          this.addTagTxt = "";
          // 重新获取标签列表
          this.$api.favoriteTagList({ type: "2" }).then((res) => {
            //console.log("favoriteTagList", res);
            this.tagLists = res.data;
            // 调用处理标签列表事件
            this.tagDataProcessing();
            this.shopTagOpen(item);
            this.$forceUpdate();
          });
        });
    },
    // 将店铺分配到选中的标签里去
    checkTagAdd(id, item) {
      let ids = [];
      this.tagLists.forEach((item) => {
        if (item.checked) ids.push(item.tag_id);
      });
      //console.log(this.tagLists);
      ids = ids.join(",");
      let datas = {
        id: id,
      };
      if (ids) {
        datas.tag_ids = ids;
      } else {
        datas.tag_ids = "";
      }
      this.$api.favoriteShopToTags(datas).then((res) => {
        //console.log("favoriteShopToTags", res);
        if (res.code != 0) return;
        item.tag_ids = ids;
      });
    },
    // 删除标签
    delTag(id, index) {
      this.$api
        .favoriteTagDelete({
          tag_id: id,
        })
        .then((res) => {
          //console.log("favoriteTagDelete", res);
          if (res.code != 0) return;
          this.tagLists.splice(index, 1);
        });
    },
    // 全局鼠标点击事件
    bodyCloseMenus() {
      // 关闭所有商品的标签弹窗
      this.shopData.forEach((item) => {
        item.showTagWin = false;
      });
      this.$forceUpdate();
    },
    // 标签按钮点击事件
    tabBtnClick(item) {
      // 设置选中便签的值
      item.titleChecked = !item.titleChecked;
      this.$forceUpdate();
      this.getShopsList("tagShops");
    },
    // 获取商品收藏夹标签列表并调用商品收藏夹列表获取函数
    getTagData() {
      this.$api.favoriteTagList({ type: "2" }).then((res) => {
        //console.log("favoriteTagList", res);
        this.tagLists = res.data;
        // 调用处理标签列表事件
        this.tagDataProcessing();
        // 调用获取商品收藏夹列表事件
      });
    },
    // 处理收藏夹标签数据
    tagDataProcessing() {
      this.tagLists.forEach((item) => {
        item.checked = false;
        item.titleChecked = false;
      });
    },
    // ******************商店事件*************************************************
    // 修改店铺备注
    changeNote(id, note) {
      this.$api
        .favoriteShopNoteSave({
          id: id,
          note: note,
        })
        .then((res) => {
          //console.log("favoriteShopNoteSave", res);
          if (res.code != 0) return;
        });
    },
    // 分页事件
    handleSizeChange(val) {
      //console.log(`每页 ${val} 条`);
      this.form.page_num = val;
      this.form.page = 1;
      this.getShopsList("one");
    },
    handleCurrentChange(val) {
      //console.log(`当前页: ${val}`);
      this.form.page = val;
      this.getShopsList("one");
    },
    // 滑动店铺商品列表
    shopCarouselChange(type, index) {
      if (type == "left") {
        this.$refs.shopCarousel[index].prev();
      } else if (type == "right") {
        this.$refs.shopCarousel[index].next();
      }
    },
    // 获取店铺列表
    getShopsList(type) {
      this.loading = true;
      let datas = {
        tag_ids: "",
        page: this.form.page,
        pageSize: this.form.pageSize,
        keywords: this.form.keywords,
      };

      let tagIds = [];
      this.tagLists.forEach((item) => {
        if (item.titleChecked) tagIds.push(item.tag_id);
      });
      tagIds = tagIds.join(",");
      datas.tag_ids = tagIds;
      this.$api
        .favoriteShopList(datas)
        .then((res) => {
          this.loading = false;
          //console.log("favoriteShopList", res);
          this.shopData = res.data.data;
          this.total = res.data.total;
          this.ProcessingShopData();
        })
        .catch((err) => {});
      this.ProcessingShopData();
    },
    //处理店铺数据
    ProcessingShopData() {
      //console.log("sdafsadf");
      //console.log(this.shopData);

      this.shopData.forEach((item) => {
        let lack = 4 - (item.goods.length % 4); //取得店铺商品数离4个的倍数差多少个商品的数量
        //console.log("缺多少个商品", lack);
        //差多少个就从最前面取多少个填充到最后
        for (let i = 0; i < lack; i++) {
          item.goods.push(item.goods[i]);
        }
        //  把单个店铺的商品分成四个一组
        item.goods = this.$fun.changeArr(item.goods, 4);
        item.showTagWin = false;
      });
      //console.log(this.shopData);
      this.$forceUpdate();
    },

    // 打开分配店铺标签窗口
    shopTagOpen(item) {
      this.shopData.forEach((item) => {
        item.showTagWin = false;
      });
      let shopsTagIds = item.tag_ids.split(",");

      // 初始化商品标签列表数据
      this.tagLists.forEach((item) => {
        //console.log(shopsTagIds, String(item.tag_id));
        if (shopsTagIds.indexOf(String(item.tag_id)) != -1) {
          item.checked = true;
        } else {
          item.checked = false;
        }
      });

      item.showTagWin = true;
      this.$forceUpdate();
    },
    // 删除店铺
    delshop(index, item) {
      this.$refs.dialog.open(
        this.$fanyi("是否删除此店铺"),
        () => {
          this.$api.favoriteShopDelete({ id: item.id }).then((res) => {
            //console.log("favoriteGoodsDelete", res);
            if (res.code != 0) {
              return;
            }

            this.getShopsList();
          });
        },
        () => {}
      );
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
#userOrder {
  @import "../../../../css/mixin";
  width: 100%;

  > div {
    background: #ffffff;
    border: 1px solid #e1e1e1;
    padding: 20px;

    .ohead {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .myInput {
        height: 40px;
        border-radius: 4px;

        position: relative;
        display: flex;
        align-items: center;
        > i {
          width: 20px;
          height: 24px;
          margin: 9px 11px 9px 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
        }
        > input {
          border: none;
          width: 400px;
          border-radius: 4px;
          border: 1px solid #c0c4cc;
          height: 100%;
          font-size: 14px;
          width: 450px;
          padding-left: 40px;
          padding-right: 10px;
          transition: 0.3s;
          &:focus {
            border-color: #1e2997;
          }
        }
        > button {
          width: 80px;
          height: 100%;
          background: $homePageSubjectColor;
          border-radius: 4px;
          font-size: 16px;

          color: #ffffff;
          line-height: 18px;

          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
    .btns {
      display: inline-block;
      position: relative;
      left: 80px;
      top: 7px;
      > button {
        width: 96px;
        height: 40px;
        background: #1e2997;
        border-radius: 4px;
        font-size: 16px;

        color: #ffffff;
      }
    }
    .tallys {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 24px;
      span {
        cursor: pointer;
        display: block;
        height: 24px;
        border-radius: 5px;
        border: 1px solid #ededed;
        margin: 0 10px 10px 0;
        padding: 4px 8px;
        font-size: 12px;
        &.active {
          border: 1px solid #47bd9b;
          color: #47bd9b;
        }
      }
    }
    .shops {
      width: 100%;
      min-height: 300px;
      .shopsOpt {
        width: 100%;
        height: 160px;
        background: #fbfbff;
        border-radius: 10px;
        border: 1px solid rgba(30, 41, 151, 0.3);
        padding: 30px 20px 20px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        .optLeft {
          width: 396px;
          height: 100%;
          position: relative;
          .goodsTitleBox {
            line-height: 15px;
            font-weight: bold;
            color: #000000;
            font-size: 14px;
            // margin-bottom: 10px;
            cursor: pointer;
            height: 48px;

            .shopName {
              @extend .text-overflow;
              // margin-top: 6px;
              font-weight: 400;
              font-size: 16px;
              line-height: 1.2;
            }
          }
          .remark {
            margin-top: auto;
            margin-bottom: 0px;
            font-weight: bold;

            span {
              font-size: 14px;
              line-height: 20px;
              margin-right: 51px;
              display: block;
            }
            /deep/.el-input {
              width: 230px;
              height: 30px;
              .el-input__inner {
                width: 300px;
                height: 30px;
                background: #ffffff;
                border-radius: 15px;
                border: 1px solid rgba(30, 41, 151, 0.3);
              }
            }
          }
          .btns {
            img {
              width: 24px;
              height: 24px;
              cursor: pointer;
              &:first-child {
                margin-right: 20px;
              }
            }
          }
          .pop-up {
            position: absolute;
            top: 100%px;
            left: 0px;
            width: 246px;
            background: #ffffff;
            box-shadow: 0px 0px 10px 0px rgba(61, 61, 61, 0.1);
            border-radius: 10px;
            padding: 20px;
            z-index: 2;
            align-items: center;
            p {
              display: flex;
              margin-bottom: 12px;
              &:last-child {
                margin-bottom: 20px;
              }
              .che {
                width: 16px;
                height: 16px;
                background-color: #ffffff;
                border: solid 1px #dddddd;
                -webkit-border-radius: 3px;
                border-radius: 3px;
                font-size: 0.8rem;
                margin: 0;
                margin-right: 6px;
                padding: 0;
                position: relative;
                display: inline-block;
                vertical-align: top;
                cursor: default;
                -webkit-appearance: none;
                -webkit-user-select: none;
                user-select: none;
                -webkit-transition: background-color ease 0.1s;
                transition: background-color ease 0.1s;
                &:checked {
                  background: #1673ff;
                }
                &:checked::after {
                  content: "";
                  top: 1px;
                  left: 1px;
                  position: absolute;
                  background: transparent;
                  border: #fff solid 2px;
                  border-top: none;
                  border-right: none;
                  height: 6px;
                  width: 10px;
                  -moz-transform: rotate(-45deg);
                  -ms-transform: rotate(-45deg);
                  -webkit-transform: rotate(-45deg);
                  transform: rotate(-45deg);
                }
              }
              > span {
                display: block;
                height: 16px;
                max-width: 170px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 12px;

                color: #000000;
                line-height: 16px;
                margin-right: auto;
              }
            }
            .addTagBtn {
              position: relative;
              height: 30px;
              input {
                width: 90%;
                height: 100%;
                background: #ffffff;
                border-radius: 15px;
                border: 1px solid rgba(30, 41, 151, 0.3);
                padding: 0 12px;
                font-size: 14px;
              }
              /deep/.el-button {
                position: absolute;
                right: 0;
                top: 0;
                height: 30px;
                width: 64px;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 15px;
              }
            }
          }
        }
        .optRight {
          width: 580px;
          // margin: 20px 30px 0 20px;
          // 轮播图两侧样式
          display: flex;
          justify-content: space-between;
          align-items: center;
          .carouselBtn {
            width: 40px;
            height: 40px;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 20px;
            }
          }
          /deep/.el-carousel__arrow--right,
          /deep/.el-carousel__arrow--left {
            height: 0;
            overflow: hidden;
          }
          .el-carousel {
            width: 460px;
          }
          /deep/ .el-carousel__arrow {
            background: rgba(255, 255, 255, 0.5);
            i {
              color: $homePageSubjectColor;
            }
          }
          /deep/.el-carousel__button {
            width: 12px;
            height: 12px;
            background: #ffffff;
            border-radius: 50%;
          }
          /deep/.el-carousel__indicator.el-carousel__indicator--horizontal.is-active
            .el-carousel__button {
            background-color: transparent;
          }

          .optRightCon {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .shopGoods {
              width: 100px;
              height: 100px;
              cursor: pointer;
              /deep/.el-image {
                $imgSize: 100px;
                width: $imgSize;
                height: $imgSize;
                transition: 0.3s;
                text-align: center;
                i {
                  font-size: $imgSize/4;
                  line-height: $imgSize;
                }
              }
            }
          }
        }
      }
    }
    .tishi {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 300px;
      font-size: 25px;
      font-weight: bold;
      color: #bcb8b8;
    }
    .allpag {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 25px 0;
    }
  }
}
</style>
